var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "题库基本信息",
        },
        on: {
          "head-save": _vm.headSave,
          "head-save-back": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
        on: {
          "update:dataForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:data-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
      _c("head-layout", {
        ref: "headLayout",
        attrs: {
          "head-btn-options": _vm.tableHeadOption,
          "head-title": "题目选项明细",
        },
        on: { "head-remove": _vm.headRemove, "head-add-tabs": _vm.headAdd },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
        },
        on: { "row-remove": _vm.rowRemove },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }